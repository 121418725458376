<template>
  <div>
    <b-row style="margin-bottom: 0px">

      <!-- <div id="2222">
        <GmapAutocomplete @place_changed='setPlace' />
        <button @click='addMarker'>
          Add
        </button>
        <GmapMap :center="{ lat: 0, lng: 0 }" :zoom='12' style='width:400px;  height: 400px;'>
          <GmapMarker :key="index" v-for="(m, index) in markers" :position="m.position" @click="center = m.position" />
        </GmapMap>
      </div> -->


      <!-- Resumen Distance an location -->
      <b-col md="3" cols="12" class="mb-2">
        <div style="background: #efefef; color: #0e0d0d">
          <p class="mb-0" style="padding-left: 9px" v-if="isSelfpay == false">
            <span style="font-weight: bold">Company:</span>
            {{ getCompanyName(dataRequest) }}
          </p>
          <p class="mb-0" style="padding-left: 9px">
            <span style="font-weight: bold">Patient:</span>
            {{ getSelfPayName(dataRequest) }}
          </p>
          <p class="mb-0" style="padding-left: 9px">
            <span style="font-weight: bold">Phone:</span>
            {{ getPhoneSelfpay(dataRequest) }}
          </p>
          <p class="mb-0" style="padding-left: 9px">
            <span style="font-weight: bold">Email:</span>
            {{ getEmailSelfpay(dataRequest) }}
          </p>
          <table>
            <tr>
              <td>
                <p class="mb-0" style="padding-left: 9px">
                  <span style="font-weight: bold">Reference number:</span>
                </p>
              </td>
              <td>
                <b-form-input v-model="dataRequest.reference_number" size="sm" md="2" />
              </td>
            </tr>
          </table>


        </div>
      </b-col>
      <b-col md="9" cols="12" class="display: flex;">

        <table class="tbTotal">
          <tr v-if="dataRequest.from !== '' && dataRequest.to !== ''">
            <td>
              <span style="font-weight: bold"> Pickup address: </span>
            </td>
            <td>
              {{ reserva.from.from }}
            </td>
          </tr>
          <tr v-if="dataRequest.from !== '' && dataRequest.to !== ''">
            <td>
              <span style="font-weight: bold"> Destination: </span>
            </td>
            <td>
              {{ reserva.to.to }}
            </td>
          </tr>
          <tr v-if="dataRequest.from !== '' && dataRequest.to !== ''">
            <td>
              <span style="font-weight: bold"> Distance: </span>
            </td>
            <td>
              <span v-if="dataRequest.is_automatic_miles == 1"> {{ toFixed(dataRequest.total_miles, 0) }} miles</span>
              <b-form-input type="number" ref="input-price" style="width: 170px;"
                v-if="dataRequest.is_automatic_miles == 0" v-model="dataRequest.total_miles" size="sm" md="2" />
            </td>
          </tr>
          <tr v-if="dataRequest.from !== '' && dataRequest.to !== ''">
            <td>
              <span style="font-weight: bold"> Calculation distance: </span>
            </td>
            <td>
              <div style="display:flex">
                <b-form-radio @change="doCalculations()" v-model="dataRequest.is_automatic_miles"
                  name="radios-automatic-miles" value="1">Automatic distance
                </b-form-radio>
                <b-form-radio class="ml-1 mr-1" v-model="dataRequest.is_automatic_miles" name="radios-automatic-miles"
                  value="0">Fixed distance</b-form-radio>
              </div>
            </td>
          </tr>
          <tr v-if="listAdditionalStopsSelected.length > 0">
            <td>
              <span style="font-weight: bold"> Additonal stops: </span>
            </td>
            <td style="padding:0">
              <table>
                <tr v-for="item in listAdditionalStopsSelected" :key="item.id">
                  <td>*** {{ item.to }} </td>
                </tr>
              </table>
            </td>
          </tr>


          <tr v-if="dataRequest.from !== '' && dataRequest.to !== ''">
            <td>
              <span style="font-weight: bold"> Price </span>
              (not including extracharge):
            </td>
            <td>
              <span v-if="dataRequest.is_automatic_price == 1"> {{ toFixed(dataRequest.price, 2) }}</span>
              <b-form-input type="number" ref="input-price" style="width: 170px;"
                v-if="dataRequest.is_automatic_price == 0" v-model="dataRequest.price" size="sm" md="2"
                :disabled="this.$hasPermission('modifying-rates') === false" />
            </td>
          </tr>

          <tr v-if="dataRequest.from !== '' && dataRequest.to !== ''">
            <td>
              <span style="font-weight: bold"> Price </span>
              (including extracharge):
            </td>
            <td>
              <b-form-input disabled type="number" ref="input-price" style="width: 170px;background: beige;"
                v-model="totalExtraCharge" size="sm" md="2" />

            </td>
          </tr>
          <tr v-if="dataRequest.from !== '' && dataRequest.to !== ''">
            <td>
              <span style="font-weight: bold"> Calculation price: </span>
            </td>
            <td>
              <div style="display:flex">
                <b-form-radio @change="calculatePrice" v-model="dataRequest.is_automatic_price"
                  :disabled="this.$hasPermission('modifying-rates') === false" name="radios-automatic-price"
                  value="1">Automatic price
                </b-form-radio>
                <b-form-radio class="ml-1 mr-1" v-model="dataRequest.is_automatic_price" name="radios-automatic-price"
                  @change="getChargesBooking(dataRequest.id)"
                  :disabled="this.$hasPermission('modifying-rates') === false" value="0">Fixed price</b-form-radio>
              </div>
            </td>
          </tr>
          <tr v-if="tripSupported === 'no'">
            <td>
              <span style="font-weight: bold">Trip Supported</span>
            </td>
            <td>
              {{ tripSupported }}
            </td>
          </tr>
        </table>
      </b-col>
      <!--End Resumen Distance an location -->
    </b-row>

    <b-row v-if="dataRequest.status_code" class="mb-2">
      <!-- <h4><span style="font-weight: bold;">Status trip:</span> <span>{{ dataRequest.status_code.status }}</span></h4> -->

      <b-col md="4">
        <b-form-group style="    margin-bottom: 0px;" label="Collab user">
          <validation-provider #default="{ errors }">
            <b-form-input disabled size="sm" v-model="dataRequest.assigned_employee" id="assigned_employee"
              :maxlength="50" />
            <small class="text-danger" v-if="errors[0]">{{
              errors[0]
            }}</small>
          </validation-provider>
        </b-form-group>
        <v-select @close="assingEmployee" v-model="selectedAssingedEmployee" label="title" :options=optionsUser
          class="select-size-sm" />
      </b-col>
      <b-col md="4">
        <b-form-group style="    margin-bottom: 0px;" label="Provider relations">
          <validation-provider #default="{ errors }">
            <b-form-input disabled size="sm" v-model="dataRequest.provider_relations" id="provider_relations"
              :maxlength="50" />
            <small class="text-danger" v-if="errors[0]">{{
              errors[0]
            }}</small>
          </validation-provider>
        </b-form-group>
        <v-select @close="assingProviderRelations" v-model="selectedProviderRelations" label="title"
          :options=optionsUserProviderRelations class="select-size-sm" />
      </b-col>

      <b-col md="4">
        <span class="mt-0" style="font-weight: bold;">Status trip</span>
        <v-select @close="changeStatusBooking" v-model="selectedStatusCode" label="title" :options=optionsStatusCodes
          class="select-size-sm" style="margin-top:7px" />
      </b-col>


    </b-row>
    <b-row style="margin-left: 0px">
      <b-button v-if="this.$hasPermission('modifying-rates')" v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        v-b-modal.modal-prices variant="outline-primary">
        Change Prices
      </b-button>
      <!--Notes Details toggle button-->
      <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" v-b-toggle.collapse-2 variant="outline-primary"
        style="width: 200px" class="ml-2">
        Notes Details
      </b-button>
      <!--History log toggle button-->
      <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" v-b-toggle.collapse-3 variant="outline-primary"
        style="width: 200px" class="ml-2">
        History logs
      </b-button>
      <b-button v-if="this.$hasPermission('modifying-rates')" v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        v-b-modal.bv-modal-sms variant="outline-primary" class="ml-2">
        Send SMS
      </b-button>
    </b-row>

    <!-- Notes Details Details content -->
    <b-row style="margin-bottom: 20px; margin-left: 0px">
      <b-col md="12">
        <b-collapse id="collapse-2" class="mt-2" md="12">
          <b-card class="border mb-0" md="12">
            <b-row>
              <h5 style="margin: 12px">Notes Details</h5>
            </b-row>
            <b-row>
              <b-col md="12 text-right  mb-1">
                <button v-b-modal.bv-modal-example class="btn btn-primary">
                  Add New Note
                </button>
              </b-col>
            </b-row>
            <b-row md="12">
              <b-col md="12">
                <b-table-simple hover small caption-top responsive>
                  <b-thead head-variant="dark">
                    <b-tr>
                      <b-th>User</b-th>
                      <b-th>Comment</b-th>
                      <b-th>Note type</b-th>
                      <b-th>Status</b-th>
                      <b-th>Date</b-th>
                      <b-th></b-th>
                    </b-tr>
                  </b-thead>
                  <b-tbody>
                    <b-tr v-for="(log, index) in dataRequest.Bookinglogs" :key="index">
                      <template v-if="log.type_log == 'note' && log.type_note != 'Sms' && log.type_note != 'billing'">
                        <b-td>
                          {{ log.username }}
                        </b-td>
                        <b-td>
                          {{ log.comments }}
                        </b-td>
                        <b-td>
                          {{ log.type_note }}
                        </b-td>
                        <b-td style="min-width: 300px">
                          {{ log.status }}
                        </b-td>
                        <b-td style="min-width: 200px">
                          {{ log.datetime_log }}
                        </b-td>
                        <b-td>
                          <td>
                            <b-dropdown variant="link"
                              v-if="hasPermissionEditBookingNote || hasPermissionEditBookingNote" no-caret
                              :right="$store.state.appConfig.isRTL" transition="scale-transition" :offset-y="true">
                              <template #button-content>
                                <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
                              </template>
                              <template v-slot:activator="{ on, attrs }">
                                <b-btn color="primary" v-bind="attrs" v-on="on" icon ripple>
                                </b-btn>
                              </template>
                              <b-list-group v-if="hasPermissionEditBookingNote" style="padding: 0px; margin-bottom: 0px"
                                dense rounded>
                                <b-list-group-item style="padding: 0" class="urlPagina" :ripple="false">
                                  <b-list-group-item class="font-weight-bold" @click="openModalEditNote(log)"
                                    style="border: none; padding: 0px; color: #7367f0">
                                    <feather-icon icon="CheckIcon" />Edit
                                  </b-list-group-item>
                                </b-list-group-item>
                              </b-list-group>
                              <b-list-group v-if="hasPermissionDeleteBookingNote"
                                style="padding: 0px; margin-bottom: 0px" dense rounded>
                                <b-list-group-item style="padding: 0" class="urlPagina" :ripple="false">
                                  <b-list-group-item class="font-weight-bold"
                                    style="border: none; padding: 0px; color: #7367f0" @click="deleteNote(log.id)">
                                    <feather-icon icon="CheckIcon" />Delete
                                  </b-list-group-item>
                                </b-list-group-item>
                              </b-list-group>
                              <!--End Timer -->
                            </b-dropdown>
                          </td>
                        </b-td>
                      </template>
                    </b-tr>
                  </b-tbody>
                </b-table-simple>
              </b-col>
            </b-row>
          </b-card>
        </b-collapse>
      </b-col>
    </b-row>

    <!-- History logs Details content -->
    <b-row style="margin-bottom: 20px; margin-left: 0px">
      <b-col md="12">
        <b-collapse id="collapse-3" class="mt-2" md="12">
          <b-card class="border mb-0" md="12">
            <b-row>
              <h5 style="margin: 12px">History logs</h5>
            </b-row>
            <b-row md="12">
              <b-col md="12">
                <b-table-simple hover small caption-top responsive>
                  <b-thead head-variant="dark">
                    <b-tr>
                      <b-th>User</b-th>
                      <b-th>Comment</b-th>
                      <b-th>Status</b-th>
                      <b-th>Date</b-th>
                    </b-tr>
                  </b-thead>
                  <b-tbody>
                    <b-tr v-for="(log, index) in dataRequest.Bookinglogs" :key="index">
                      <template v-if="log.type_log == 'history'">
                        <b-td>
                          {{ log.username }}
                        </b-td>
                        <b-td>
                          {{ log.comments }}
                          </small>
                        </b-td>
                        <b-td style="min-width: 300px">
                          {{ log.status }}
                        </b-td>
                        <b-td style="min-width: 200px">
                          {{ log.datetime_log }}
                        </b-td>
                      </template>
                    </b-tr>
                  </b-tbody>
                </b-table-simple>
              </b-col>
            </b-row>
          </b-card>
        </b-collapse>
      </b-col>
    </b-row>

    <b-form>
      <validation-observer ref="rulesFormBooking" tag="form">
        <b-row>
          <b-col md="12">
            <b-row>
              <b-col md="4">
                <b-form-group label="Trip type">
                  <validation-provider #default="{ errors }" rules="required" name="Trip Type">
                    <v-select @input="doCalculations()" v-model="selectedTripType" :options="optionsTripType"
                      label="title" placeholder="Please select" @close="calculatePrice()" />
                    <small class="text-danger" v-if="errors[0]">{{
                      errors[0]
                    }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group label="Service type">
                  <validation-provider #default="{ errors }" rules="required" name="Service Type">
                    <v-select v-model="selectedService" :options="optionsServiceOptions" label="title"
                      placeholder="Service Type" @close="changeServiceType()" />
                    <small class="text-danger" v-if="errors[0]">{{
                      errors[0]
                    }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group label="Reason of Visit">
                  <validation-provider #default="{ errors }" rules="required" name="Reason of Visit">
                    <v-select v-model="selectedSurgery" :options="optionsSurgery" label="title"
                      placeholder="Please select some item" />
                    <small class="text-danger" v-if="errors[0]">{{
                      errors[0]
                    }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="7" lg="6">
                <b-form-group label="Pickup time">
                  <validation-provider #default="{ errors }" rules="required" name="It's pickup on time">
                    <v-select v-model="selectedIsPickupOntime" :options="optionsIsPickupOntime" label="title"
                      placeholder="Please select some item" :state="errors.length > 0 ? false : null" />
                    <small class="text-danger" v-if="errors[0]">This field is required</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="2">
                <b-form-group label="It's walk in">
                  <b-form-select @close="setValuesWalkIn()" v-model="dataRequest.is_walkin" :options="optionsWalkIn">
                  </b-form-select>
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>

          <b-col md="6">
            <b-form-group label="Pick up location (e.g. aparment #4432, house color blue)">
              <b-form-input v-model="dataRequest.pick_up_location" id="pick_up_location" :maxlength="250" />
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group label="Drop off location (e.g. aparment #4432, house color blue)">
              <b-form-input v-model="dataRequest.drop_off_location" id="drop_off_location" :maxlength="250" />
            </b-form-group>
          </b-col>

          <b-col md="6">
            <div style="display:flex" class="mb-2">
              <b-form-radio v-model="dataRequest.is_automatic_pickup_address" name="radios-automatic-pickup-address"
                value="1">Automatic pickup address
              </b-form-radio>
              <b-form-radio class="ml-1 mr-1" v-model="dataRequest.is_automatic_pickup_address"
                name="radios-automatic-pickup-address" value="0">Manual pickup address</b-form-radio>
            </div>


            <template v-if="dataRequest.is_automatic_pickup_address == 1" class="col-6">
              <b-form-group label="Pickup address">
                <b-form-input label="Pick up address" disabled v-model="reserva.from.from" />
                <gmap-autocomplete class="form-control" :value="searchPickupAddress" @place_changed="initMarkerFrom">
                </gmap-autocomplete>
              </b-form-group>
            </template>
            <template v-if="dataRequest.is_automatic_pickup_address == 0">
              Pickup address
              <b-form-input v-model="reserva.from.from" @change="doCalculations();" />
              <table>
                <tr>
                  <td>
                    <span>Latitude</span>
                  </td>
                  <td>
                    <span class="ml-2">Longitude</span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <validation-provider #default="{ errors }" rules="required" name="Latitude">
                      <b-form-input v-model="fromLatitude" @change="doCalculations();" />
                      <small class="text-danger" v-if="errors[0]">{{
                        errors[0]
                      }}</small>
                    </validation-provider>
                  </td>
                  <td>
                    <validation-provider #default="{ errors }" rules="required" name="Latitude">
                      <b-form-input class="ml-2" v-model="fromLongitude" @change="doCalculations();" />
                      <small class="text-danger ml-2" v-if="errors[0]">{{
                        errors[0]
                      }}</small>
                    </validation-provider>
                  </td>
                </tr>
              </table>
            </template>


          </b-col>
          <b-col md="6">
            <div style="display:flex" class="mb-2">
              <b-form-radio v-model="dataRequest.is_automatic_destination_address"
                name="radios-automatic-destination-address" value="1">Automatic destination address
              </b-form-radio>
              <b-form-radio class="ml-1 mr-1" v-model="dataRequest.is_automatic_destination_address"
                name="radios-automatic-destination-address" value="0">Manual pickup address</b-form-radio>
            </div>
            <template v-if="dataRequest.is_automatic_destination_address == 1" class="col-6">
              <b-form-group label="Destination address">
                <b-form-input disabled v-model="reserva.to.to" />
                <gmap-autocomplete class="form-control" :value="searchDestinationAddress" @place_changed="initMarkerTo">
                </gmap-autocomplete>
              </b-form-group>
            </template>

            <template v-if="dataRequest.is_automatic_destination_address == 0">
              Destination address {{ reserva.to.to }}
              <b-form-input v-model="reserva.to.to" @change="doCalculations();" />
              <table>
                <tr>
                  <td>
                    <span>Latitude</span>
                  </td>
                  <td>
                    <span class="ml-2">Longitude</span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <validation-provider #default="{ errors }" rules="required" name="Latitude">
                      <b-form-input v-model="toLatitude" @change="doCalculations();" />
                      <small class="text-danger" v-if="errors[0]">{{
                        errors[0]
                      }}</small>
                    </validation-provider>
                  </td>
                  <td>
                    <validation-provider #default="{ errors }" rules="required" name="Longitude">
                      <b-form-input class="ml-2" v-model="toLongituded" @change="doCalculations();" />
                      <small class="text-danger ml-2" v-if="errors[0]">{{
                        errors[0]
                      }}</small>
                    </validation-provider>
                  </td>
                </tr>
              </table>
            </template>


          </b-col>

          <b-col md="3">
            <b-form-group label="State">
              <validation-provider #default="{ errors }" rules="required">
                <v-select v-model="selectedState" label="title" :options="listStates"
                  @input="getCitiesByState(selectedState.value, '')" />
                <small class="text-danger" v-if="errors[0]">This field is required</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group label="City">
              <validation-provider #default="{ errors }" rules="required">
                <v-select @input="setTimeZone(null)" v-model="selectedCity" label="title" :options="listCities"
                  :disabled="listCities.length == 0" />
                <small class="text-danger" v-if="errors[0]">This field is required</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="4" lg="3">
            <b-form-group label="Time zone">
              <validation-provider #default="{ errors }" rules="required">
                <v-select v-model="selectedTimeZone" label="title" :options="optionsTimezone" />
                <small class="text-danger" v-if="errors[0]">This field is required</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col cols="12" md="5" lg="3">
            <b-form-group label="Appointment date">
              <validation-provider #default="{ errors }" rules="required" name="Appointment date">
                <b-form-datepicker v-model="dataRequest.booking_date" locale="en" placeholder="0/00/0000" />
                <small class="text-danger" v-if="errors[0]">{{
                  errors[0]
                }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12" md="4" lg="3" v-if="dataRequest.is_walkin == 0">
            <b-form-group label="Appointment time">
              <validation-provider #default="{ errors }" rules="required" name="Appointment time">
                <input v-model="appoinmentDatetimeSelected" type="time" lang="en-us"
                  style="padding: 8px; border-radius: 9px; border: 2px solid #ececec;" />
                <br>
                <small class="text-danger" v-if="errors[0]">{{
                  errors[0]
                }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12" md="4" lg="3">
            <b-form-group label="Approximate pickup time">
              <validation-provider #default="{ errors }" rules="" name="Suggested pickup time">
                <input v-model="dataRequest.pickup_time" type="time" lang="en-us"
                  style="padding: 8px; border-radius: 9px; border: 2px solid #ececec;" />
                <br>
                <small class="text-danger" v-if="errors[0]">{{
                  errors[0]
                }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col cols="12" md="4" lg="3" v-if="selectedTripType.value == ROUNTRIP && dataRequest.is_walkin == 0">
            <b-form-group label="Approximate return time">
              <validation-provider #default="{ errors }" rules="" name="Approximate return time">
                <input v-model="dataRequest.approximately_return_time" type="time" lang="en-us"
                  style="padding: 8px; border-radius: 9px; border: 2px solid #ececec;" />
                <br>
                <small class="text-danger" v-if="errors[0]">{{
                  errors[0]
                }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="5" lg="3">
            <b-form-group>
              <validation-provider #default="{ errors }" rules="required|regex:^[0-9][0-9]:[0-9][0-9]$"
                name="Waiting time">
                <label for="" style="margin-bottom: 10px">Waiting time(hh:mm) Price ${{ feeWaitingTime }}/hour</label>
                <b-form-input @change="calculatePrice(false)" v-model="dataRequest.total_waiting_time" v-mask="'##:##'"
                  hint="hh:mm" placeholder="hh:mm" />
                <small class="text-danger" v-if="errors[0]">{{
                  errors[0] + " hh:mm"
                }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col cols="12" md="3">
            <b-form-group label="Exceeded wait time">
              <validation-provider #default="{ errors }" rules="required" name="Exited wait time">
                <b-form-input v-model="dataRequest.exceeded_wait_time" :state="errors.length > 0 ? false : null" />
                <small class="text-danger" v-if="errors[0]">{{
                  errors[0]
                }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12" md="3">
            <b-form-group label="Central zone time">
              <validation-provider #default="{ errors }" name="Garage time">
                <b-form-input :value="getGarageTime(dataRequest.garage_time)" disabled
                  :state="errors.length > 0 ? false : null" />
                <small class="text-danger" v-if="errors[0]">{{
                  errors[0]
                }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <!-- Field: Username -->
          <b-col cols="12" md="3">
            <b-form-group label="Facility Name">
              <validation-provider #default="{ errors }" rules="" name="Facility Name">
                <b-form-input v-model="dataRequest.facility_name" id="facility_name" :maxlength="50" />
                <small class="text-danger" v-if="errors[0]">{{
                  errors[0]
                }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!-- Field: Full Name -->
          <b-col cols="12" md="3">
            <b-form-group label="Doctor's Name">
              <validation-provider #default="{ errors }" rules="" name="Doctor's Name">
                <b-form-input v-model="dataRequest.doctor_name" id="doctor_name" :maxlength="50" />
                <small class="text-danger" v-if="errors[0]">{{
                  errors[0]
                }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12" md="3">
            <b-form-group label="Facility Phone Number">
              <validation-provider #default="{ errors }" rules="" name="Facility Phone Number">
                <cleave id="phone" v-model="dataRequest.facility_phone_number" class="form-control" :raw="false"
                  :options="optionsCleave.phone" placeholder="1234 567 8900" />
                <small class="text-danger" v-if="errors[0]">{{
                  errors[0]
                }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>



        <b-row>
          <b-col md="12">
            <hr />
            <h5>Driver Information</h5>
            <hr />
          </b-col>
          <b-col md="3">
            <b-form-group label="Driver price">
              <b-form-input v-model="dataRequest.driver_price" type="number" :maxlength="8" id="driver_price" />
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group label="Additional charges driver">
              <b-form-input v-model="dataRequest.additional_charges_driver" type="number" :maxlength="8" />
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group label="No show/Cancelation fee for driver">
              <b-form-input v-model="dataRequest.no_show_cancelation_fee_driver" type="number" :maxlength="8" />
            </b-form-group>
          </b-col>
          <b-col v-if="enableControl === true" cols="12 text-right">
            <b-button class="btn btn-primary" @click="saveChanges" :disabled="disablebUpdateButton" id="updateButton">
              <span v-if="disablebUpdateButton == false">Update booking</span>
              <span v-if="disablebUpdateButton == true">Please wait...</span>
            </b-button>
          </b-col>
        </b-row>
      </validation-observer>
    </b-form>


    <template>
      <b-row>
        <b-col md="12">
          <hr>
          <h4>List additional stops</h4>

          <b-button v-if="currentAddtionalStop.id" variant="primary" @click="resetCurrentAddtionalStop">
            Add an additional stop
          </b-button>
          <table style="width: 100%;">
            <tr>
              <td>
                <label>Address</label>
              </td>
              <td>
                Note
              </td>
              <td></td>
            </tr>
            <tr>
              <td>
                <div style="z-index: 1040 !important;">
                  <b-form-input v-model="currentAddtionalStop.to" disabled />
                  <gmap-autocomplete :value="searchAdditionalStop" class="form-control"
                    @place_changed="initMarkerAdditionalStop">
                  </gmap-autocomplete>
                </div>

              </td>
              <td style="vertical-align:top">
                <b-form-input v-model="currentAddtionalStop.note" />
              </td>
              <td style="text-align: right;max-width: 85px;vertical-align:top">
                <b-button v-if="currentAddtionalStop.id" variant="danger" @click="resetCurrentAddtionalStop()">
                  Cancel
                </b-button>
                <b-button v-if="currentAddtionalStop.id == null" variant="success" @click="addAdditionalStop">
                  Add
                </b-button>
                <b-button v-if="currentAddtionalStop.id" variant="success" @click="updateAdditionalStop">
                  Update
                </b-button>
              </td>
            </tr>

          </table>


          <b-table-simple hover small caption-top responsive class="mt-2" v-if="listAdditionalStopsSelected.length > 0">
            <b-thead head-variant="dark">
              <b-tr>
                <b-th>Address</b-th>
                <b-th>Note</b-th>
                <b-th style="max-width: 30px;"></b-th>
              </b-tr>
            </b-thead>
            <b-tbody>
              <b-tr v-for="item in listAdditionalStopsSelected" :key="item.id">
                <b-td>{{ item.to }} </b-td>
                <b-td>{{ item.note }} </b-td>
                <b-td style="text-align:right;">
                  <b-button style="    padding-left: 35px;padding-right: 35px;" v-if="enableControl == true"
                    variant="primary" @click="editAddtionalStop(item)">
                    Edit
                  </b-button>
                  <b-button class="ml-2" v-if="enableControl == true" variant="danger"
                    @click="deleteAdditionalStop(item)">
                    Delete
                  </b-button>
                </b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>

        </b-col>

      </b-row>




      <b-col class="mt-2" md="12">

      </b-col>
      <!-- End Additional stops -->
    </template>

    <template v-if="dataRequest.id && hasPermissionInternalfinancialreports()">
      <BillingNotes :bookingId="dataRequest.id" :statusBooking="this.dataRequest.status"></BillingNotes>
    </template>



    <b-modal id="bv-modal-example" centered hide-footer size="lg">
      <div class="d-block text-center">
        <h3>Write a Note</h3>
        <b-row>
          <b-col>
            <b-form-group>
              <b-form-textarea v-model="messageText" @mouseover="changeColorMessage()"
                v-bind:class="{ 'border border-danger': isMessageText == false }" rows="6" />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <div style="display:flex" class="mb-1">
            <b-form-radio class="ml-1" name="radios-type-note" v-model="typeNote" value="Internal note">Internal
              note</b-form-radio>
            <b-form-radio class="ml-1 mr-1" name="radios-type-note" v-model="typeNote" value="Note for driver">Note for
              driver</b-form-radio>
            <b-form-radio class="ml-1 mr-1" name="radios-type-note" v-model="typeNote" value="Note for client">Note for
              client</b-form-radio>
            <b-form-radio class="ml-1 mr-1" name="radios-type-note" v-model="typeNote" value="Note for billing">Note for
              billing</b-form-radio>
          </div>
        </b-row>
        <b-row>
          <b-col cols="12" md="9">
            <v-select v-model="selectedDescriptionNote" transition="" label="title" :options="optionsNotes"
              class="select-size-sm" />
          </b-col>
          <b-col cols="12" md="3">
            <b-button size="sm" @click="addDescriptionNote">Add</b-button>
          </b-col>
        </b-row>
      </div>
      <b-row class="mt-3">
        <b-col>
          <b-button block @click="saveNote(typeNote, 'note')">Save</b-button>
        </b-col>
        <b-col>
          <b-button block @click="$bvModal.hide('bv-modal-example')">Close</b-button>
        </b-col>
      </b-row>
    </b-modal>

    <b-modal id="bv-modal-sms" centered hide-footer size="lg">
      <div class="d-block text-center">
        <h3>Write a SMS</h3>
        <b-col>
          <b-form-group>
            <b-form-textarea v-model="messageText" @mouseover="changeColorMessage()"
              v-bind:class="{ 'border border-danger': isMessageText == false }" rows="6" />
          </b-form-group>
        </b-col>
        <b-col>
          <div>
            <b-form-group>
              <b-form-checkbox v-model="isDefaultMessage" @change="setDefaulText" style="float: right;">
                Default sms message
              </b-form-checkbox>
            </b-form-group>
          </div>
        </b-col>
        <b-col>
          <b-form-group class="mb-1">
            <div style="display: flex; gap: 10px;">
              <b-form-checkbox id="passanger" v-model="messageSendTo" name="passanger" value="passanger"
                unchecked-value="" class="col-4">
                Send SMS note to Passenger
              </b-form-checkbox>
              <b-form-input v-model="dataRequest.alternative_number_passenger" class="col-8"
                placeholder="Alternative Number" />
            </div>
          </b-form-group>
          <b-form-group class="mb-0">
            <div style="display: flex; gap: 10px">
              <b-form-checkbox id="driver" v-model="messageSendTo" name="driver" value="driver" unchecked-value=""
                class="col-4">
                Send SMS note to Driver
              </b-form-checkbox>
              <b-form-input class="col-8" v-model="dataRequest.alternative_number_driver"
                placeholder="Alternative Number" />
            </div>
          </b-form-group>
        </b-col>
      </div>
      <b-row class="mt-3">
        <b-col>
          <b-button block @click="saveNote('Sms', 'sms')">Save</b-button>
        </b-col>
        <b-col>
          <b-button block @click="$bvModal.hide('bv-modal-example')">Close</b-button>
        </b-col>
      </b-row>
    </b-modal>

    <b-modal ref="modal-create-rates" id="modal-create-rates" cancel-only centered size="sm"
      title="There are no rates for this trip">
      To create a rate visit this page
      <span style="color: #9214b5;cursor: pointer;" @click="openCreateRate">Create rate</span>
      <template #modal-footer>
        <div class="w-100">
        </div>
      </template>
    </b-modal>

    <b-modal id="modal-prices" v-if="this.$hasPermission('modifying-rates')" cancel-only centered size="lg"
      title="Change Prices">
      <BookingEditPrices @load-data="loadData" :bookingId="reservaId"></BookingEditPrices>
      <template #modal-footer>
        <div class="w-100">
        </div>
      </template>
    </b-modal>

    <b-modal ref="modal-crud-notes" id="modal-crud-notes" cancel-only centered size="md" title="Edit note">
      <b-form-group label="Comment">
        <b-form-textarea v-model="note.comments" rows="4" />
      </b-form-group>

      <b-row>
        <div style="display:flex" class="mb-1">
          <b-form-radio class="ml-1" name="radios-type-note" v-model="note.type_note" value="Internal note">Internal
            note</b-form-radio>
          <b-form-radio class="ml-1 mr-1" name="radios-type-note" v-model="note.type_note" value="Note for driver">Note
            for
            driver</b-form-radio>
          <b-form-radio class="ml-1 mr-1" name="radios-type-note" v-model="note.type_note" value="Note for client">Note
            for
            client</b-form-radio>
          <b-form-radio class="ml-1 mr-1" name="radios-type-note" v-model="note.type_note" value="Note for billing">Note
            for
            billing</b-form-radio>
        </div>
      </b-row>

      <b-row>
        <b-col cols="12 text-right">
          <b-button class="btn btn-primary" @click="updateNote">
            Save
          </b-button>
        </b-col>
      </b-row>

      <template #modal-footer>
        <div class="w-100">
        </div>
      </template>
    </b-modal>

    <b-modal id="modal-additional-stops" cancel-only centered size="lg" title="Addtional stop">

      <b-col md="12" v-if="enableControl == true">
        <div>

        </div>

      </b-col>
      <template #modal-footer>
        <div class="w-100">
        </div>
      </template>
    </b-modal>

  </div>
</template>

<script>



import {
  BButton,
  BMedia,
  BAvatar,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BForm,
  BTable,
  BCard,
  BCardText,
  BCardHeader,
  BCardTitle,
  BFormCheckbox,
  BFormSelect,
  BModal,
  BInputGroup,
  BInputGroupAppend,
  VBTooltip,
  BFormDatepicker,
  BFormTimepicker,
  BTableSimple,
  BThead,
  BTr,
  BTd,
  BTh,
  BTbody,
  BTfoot,
  BAlert,
  BFormRadio,
  BDropdown,
  BListGroup,
  BListGroupItem,
  BDropdownItem,
} from "bootstrap-vue";
import { FormWizard, TabContent } from "vue-form-wizard";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import moment from "moment";
import {
  getMenuOptionsTripType,
  getMenuOptionsSurgeryOptions,
  getMenuOptionsServiceType,
  getMenuPickupOnTime,
  getMenuTimeZones,
  getMenuNotes
} from "@core/utils/menus";
import { getCentralTime, GetTotalExtraCharge } from "@core/utils/utils";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { getMessageError } from "@core/utils/utils";
import { toFixed } from "@core/utils/numbers_utils";
import { addAddressToList } from "@core/utils/gps_utils";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import { BCollapse, VBToggle } from "bootstrap-vue";
import BookingEditPrices from "@core/components/bookings/BookingEditPrices.vue";
import BillingNotes from "@core/components/billing/BillingNotes.vue";
import Cleave from 'vue-cleave-component'
import { convertToMMDDYYYY, convertToHHMM, convertToHHMM2, addTimes, timeToSecs, secsToTime } from "@core/utils/dates";
require('cleave.js/dist/addons/cleave-phone.us');

export default {
  name: "DetailsInfoService",
  components: {
    BookingEditPrices,
    BillingNotes,
    BButton,
    BMedia,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BForm,
    BTable,
    BCard,
    BCardText,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
    BFormSelect,
    BModal,
    BInputGroup,
    BInputGroupAppend,
    vSelect,
    BFormDatepicker,
    BFormTimepicker,
    FormWizard,
    BTableSimple,
    BThead,
    BTr,
    BTd,
    BTh,
    BTbody,
    BTfoot,
    ValidationProvider,
    ValidationObserver,
    AppCollapse,
    AppCollapseItem,
    BCollapse,
    moment,
    Cleave,
    BAlert,
    BFormRadio,
    BDropdown,
    BListGroup,
    BListGroupItem,
    BDropdownItem,
  },
  directives: {
    Ripple,
    "b-toggle": VBToggle,
  },
  data() {
    return {
      dataRequest: {
        id: null,
        booking_date: "",
        from: "",
        to: "",
        pickup_time: "",
        city: "",
        surgery_type: "",
        appoinment_datetime: "",
        trip_distance: 0,
        service_fee: 0,
        price: 0,
        facility_name: "",
        doctor_name: "",
        facility_phone_number: "",
        approximately_return_time: "",
        status: "",
        selfpay_id: "",
        notes: "",
        trip_type: "",
        service_type: "",
        total_additional_stops: 0,
        total_waiting_time: "00:00",
        total_miles: 0,
        listAdditionalStops: [],
        is_pickup_ontime: 0,
        state: "",
        city: "",
        time_zone: "",
        garage_time: "",
        is_reserve_by_ca: 0,
        each_way_fee: null,
        mile_fee: null,
        wait_time_fee: null,
        additional_stop_fee: null,
        cancellation_fee: null,
        minimum_fee: null,
        minimum_miles_fee: null,
        additional_miles_fee: null,
        is_automatic_fee: null,
        exceeded_wait_time: null,
        is_automatic_fee: null,
        is_automatic_miles: null,
        is_automatic_price: null,
        is_automatic_waiting_time: null,
        is_automatic_additional_stops: null,
        assigned_employee: null,
        approximately_return_time: null,
      },
      optionsWalkIn: [
        {
          text: "Yes",
          value: 1,
        },
        {
          text: "No",
          value: 0,
        },
      ],
      appoinmentDatetimeSelected: "",
      listAdditionalStopsSelected: [],
      fromSelected: "",
      toSelected: "",
      isSelfpay: true,
      selectedTripType: {},
      selectedSurgery: {},
      selectedStatusCode: {},
      selectedService: {},
      selectedAssingedEmployee: {},
      selectedProviderRelations: {},
      selectedDescriptionNote: {},
      searchAdditionalStop: null,
      searchPickupAddress: null,
      searchDestinationAddress: null,
      additionalStop: null,
      enableControl: true,
      feeAdditionalStop: 0,
      feeWaitingTime: 0,
      reserva: {
        from: "",
        to: {},
      },
      tripSupported: null,
      listAditional: {},
      inhabilitar: true,
      savedInfo: false,
      valonuevo: "",
      adicional: "",
      reservaId: 0,
      listReservas: {},
      data_time: "",
      ap_date: "",
      ap_time: "",
      optionsTripType: [],
      optionsSurgery: [],
      optionsServiceOptions: [],
      optionsTimezone: [],
      optionsStatusCodes: [],
      optionsCleave: {
        phone: {
          phone: true,
          phoneRegionCode: 'US',
        },
      },
      optionsUser: [],
      optionsUserProviderRelations: [],
      optionsNotes: [],
      disablebUpdateButton: false,
      listAddresses: [],
      listAddressesOrdered: [],
      selectedIsPickupOntime: null,
      listStates: [],
      listCities: [],
      selectedState: null,
      selectedCity: null,
      selectedTimeZone: null,
      optionsIsPickupOntime: [],

      messageSendTo: [],
      messageText: "",
      isMessageText: true,
      textDefaultMessage: "",
      isDefaultMessage: null,
      currentAlternativeNumberDriver: "",
      currentAlternativeNumberPassenger: "",
      ROUNTRIP: 'roundtrip',
      dataCities: [],
      typeNote: "Internal note",
      note: {
        id: 0,
        comments: "",
        type_note: "",
      },
      oldStatusBooking: null,
      fromLatitude: null,
      fromLongitude: null,
      toLatitude: null,
      toLongituded: null,
      noteAdditionalStop: "",
      currendAddressAddtionalStop: "",
      currentAddtionalStop: {
        id: null,
        service: null,
        to: null,
        to_coordinates: null,
        price: null,
        note: null,
        booking_id: null,
      },
      totalExtraCharge: 0,
      editAdditionalStop: false,
    };
  },
  watch: {
    "$route.params.id"() {
      this.$swal({
        title: "Loading data...",
        didOpen: () => {
          this.$swal.showLoading();
        },
      });
      this.loadInformation();
    }
  },
  methods: {
    //origin address, save address and coordinates
    initMarkerFrom(loc) {
      this.existingPlace = loc;

      this.setStateCity(loc);
      this.reserva.from.from = this.existingPlace.formatted_address;
      this.fromSelected = this.existingPlace.formatted_address;
      this.reserva.from.coordinate = this.existingPlace.geometry.location.lat() + "," + this.existingPlace.geometry.location.lng();
      this.fromLatitude = this.existingPlace.geometry.location.lat();
      this.fromLongitude = this.existingPlace.geometry.location.lng();

      this.doCalculations();
    },
    //destiny address, save address and coordinates
    initMarkerTo(loc) {
      this.existingPlace = loc;
      this.reserva.to.to = this.existingPlace.formatted_address;
      this.toSelected = this.existingPlace.formatted_address;
      this.reserva.to.coordinate = this.existingPlace.geometry.location.lat() + "," + this.existingPlace.geometry.location.lng();
      this.toLatitude = this.existingPlace.geometry.location.lat();
      this.toLongituded = this.existingPlace.geometry.location.lng();
      this.doCalculations();
    },
    //additional point, save address and coordinates
    initMarkerAdditionalStop(loc) {
      let existingPlace = loc;
      let to = existingPlace.formatted_address;
      this.currendAddressAddtionalStop = to;
      let coordinate =
        existingPlace.geometry.location.lat() +
        "," +
        existingPlace.geometry.location.lng();

      if (this.editAdditionalStop == false) {
        this.currentAddtionalStop.id = null;
      }

      this.currentAddtionalStop.service = "Additional Stop"
      this.currentAddtionalStop.to = to;
      this.currentAddtionalStop.to_coordinates = coordinate;
      this.currentAddtionalStop.price = 0;
      this.currentAddtionalStop.booking_id = this.dataRequest.id;

    },
    async setStateCity(loc) {
      //get state and city
      let lat = loc.geometry.location.lat();
      let lon = loc.geometry.location.lng();
      let timeZone = await this.getTimeZone(lat, lon);
      let state = this.getStateFromApiRespGoogle(loc);
      let city = this.getCityFromApiRespGoogle(loc);
      let place = `${city}`;
      if (state && city) {
        await this.addCityAutomatically(state, city, timeZone)
      }

      //load menu
      this.getStates(state);
      this.getCitiesByState(state, place);
      this.setTimeZone(timeZone);
    },
    async addCityAutomatically(state, city, time_zone) {
      const formData = new FormData();

      formData.append("state_full", state);
      formData.append("city", city);
      formData.append("time_zone", time_zone);

      await this.$http
        .post(`states_cities/add_city_state`, formData)
        .then((response) => {
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async getTimeZone(lat, lon) {
      let res = null;
      await this.$http
        .get(`getTimeZone/${lat}/${lon}`)
        .then((response) => {
          if (response.data.timeZoneId) {
            res = response.data.timeZoneId;
          }
        })
        .catch((error) => {
          let message = getMessageError(error);
          this.$swal({
            title: message,
            icon: "error",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        });
      return res;
    },
    //validate form before send it to server
    async validationFormInfoRequest() {
      let message = "";
      if (this.dataRequest.status == 11 && this.dataRequest.no_show_cancelation_fee_client == null) {
        message = "No show cancellation fee client must have a value";
      }
      if (this.dataRequest.status == 11 && this.dataRequest.no_show_cancelation_fee_driver == null) {
        message = "No show cancellation fee driver must have a value";
      }
      if (this.dataRequest.status == 13 && this.dataRequest.no_show_cancelation_fee_client == null) {
        message = "No show cancellation fee client must have a value";
      }

      //validate pickup address
      if (this.reserva.to_coordinates === "") {
        message = "Must select a pickup address";
      }

      if (message.length > 0) {
        this.$swal({
          title: message,
          icon: "error",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
        return false;
      }

      if (this.tripSupported === "no") {
        this.$refs.modalcreaterates.show();
        return false;
      }


      //validate destine address
      if (this.reserva.from_coordinates === "") {
        message = "Must select a Destination";
        this.$swal({
          title: message,
          icon: "error",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
        return false;
      }

      let res = await new Promise((resolve, reject) => {
        this.$refs.rulesFormBooking.validate().then((success) => {
          if (success) {
            resolve(true);
          } else {
            this.$swal({
              title: "Information was not updated",
              icon: "error",
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: false,
            });

            reject(false);
          }
        });
      });


      if (res == true) {
        if (this.oldStatusBooking != this.dataRequest.status) {
          res = await this.$swal({
            title: "Are you sure you want to change the status? If yes, please confirm that you have audited the ticket and that all information, including pricing, is correct?",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes",
            cancelButtonText: "No",
            buttonsStyling: true,
          }).then((result) => {
            return result.value;
          });
          if (res == true) {
            this.oldStatusBooking = structuredClone(this.dataRequest.status);

            //if it's cancelleted driver price and booking price is 0
            this.setCancelledValues();


            this.formSubmitted();
          } else {
            let resOption = this.optionsStatusCodes.find(
              (item) => item.value === this.oldStatusBooking
            );

            if (resOption) {
              this.selectedStatusCode = resOption;
            }
            this.dataRequest.status = structuredClone(this.oldStatusBooking);
          }
        } else {

          //if it's cancelleted driver price and booking price is 0
          this.setCancelledValues();
          this.formSubmitted();
        }
      }
    },
    setCancelledValues() {
      //if it's cancelleted driver price and booking price is 0
      if (this.dataRequest.status == 4) {
        this.dataRequest.is_automatic_price = "0";
        this.dataRequest.driver_price = 0;
        this.dataRequest.additional_charges_driver = 0;
        this.dataRequest.price = 0;
      }
    },
    //send the form to server
    async formSubmitted() {
      this.$swal({
        title: "Please, wait saving booking...",
        didOpen: () => {
          this.$swal.showLoading();
        },
      });

      this.dataRequest.appoinment_datetime = this.dataRequest.booking_date.split(" ")[0] + " " + this.appoinmentDatetimeSelected;
      this.dataRequest.service_type = this.selectedService == null ? null : this.selectedService.value;
      this.dataRequest.surgery_type = this.selectedSurgery == null ? null : this.selectedSurgery.value;
      this.dataRequest.trip_type = this.selectedTripType == null ? null : this.selectedTripType.value;
      this.dataRequest.total_additional_stops = this.listAdditionalStopsSelected.length;
      this.dataRequest.is_pickup_ontime = this.selectedIsPickupOntime == null ? null : this.selectedIsPickupOntime.value;
      this.dataRequest.state = this.selectedState == null ? null : this.selectedState.value;
      this.dataRequest.city = this.selectedCity == null ? null : this.selectedCity.value;
      this.dataRequest.time_zone = this.selectedTimeZone == null ? null : this.selectedTimeZone.value;
      this.dataRequest.garage_time = this.getCentralTime(this.dataRequest, "YYYY-MM-DD HH:mm:ss");
      this.dataRequest.waitingTime = this.dataRequest.total_waiting_time;
      this.dataRequest.status = this.selectedStatusCode.value;

      //pickup address
      if (this.dataRequest.is_automatic_pickup_address == 0) {
        let dataGps = this.reserva.from;
        dataGps.coordinate = this.fromLatitude + "," + this.fromLongitude;
        this.dataRequest.from = dataGps;
      } else {
        this.dataRequest.from = this.reserva.from;
      }

      //destination address
      if (this.dataRequest.is_automatic_destination_address == 0) {
        let dataGps = this.reserva.to;
        dataGps.coordinate = this.toLatitude + "," + this.toLongituded;
        this.dataRequest.to = dataGps;
      } else {
        this.dataRequest.to = this.reserva.to;
      }

      this.$http
        .post(
          `admin/panel/booking/${this.reservaId}/modify_bookingservice`,
          this.dataRequest
        )
        .then((res) => {
          if (res.data.status === 200) {
            this.$swal({
              title: "Data was updated successfully",
              icon: "success",
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: false,
            });
            this.saveChargesBooking();
            this.inhabilitar = true;
          } else {
            let message = getMessageError(res);
            this.$swal({
              title: message,
              icon: "error",
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: false,
            });
          }
        })
        .catch((res) => {
          let message = getMessageError(res);
          this.$swal({
            title: message,
            icon: "error",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        });
    },
    async updateFeesBooking() {
      this.$swal({
        title: "Please, wait updating fees...",
        didOpen: () => {
          this.$swal.showLoading();
        },
      });


      await this.$http
        .post(`admin/panel/booking/update_fees_booking`, this.dataRequest)
        .then((res) => {
          if (res.data.status === 200) {
            this.$swal.close();
            this.inhabilitar = true;
          } else {
            let message = getMessageError(res);
            this.$swal({
              title: message,
              icon: "error",
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: false,
            });
          }
        })
        .catch((res) => {
          let message = getMessageError(res);
          this.$swal({
            title: message,
            icon: "error",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        });
    },
    //get the info of the booking
    async getInfoBooking() {
      this.$swal({
        title: "Please, wait...",
        didOpen: () => {
          this.$swal.showLoading();
        },
      });
      this.reservaId = this.$route.params.id;

      this.$http
        .get(`admin/panel/booking/${this.reservaId}/info`)
        .then((response) => {
          this.loadData(response.data.data, true);

          //this.calculatePrice();
          this.$swal.close();
        })
        .catch((error) => {
          let message = getMessageError(error);
          this.$swal({
            title: message,
            icon: "error",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        });
    },
    async getChargesBooking(id) {
      this.$swal({
        title: "Please, wait...",
        didOpen: () => {
          this.$swal.showLoading();
        },
      });
      this.reservaId = this.$route.params.id;

      this.$http
        .get(`admin/panel/booking/${id}/info`)
        .then((response) => {
          this.booking = response.data.data;
          this.booking.base_charge = toFixed(response.data.data.base_charge, 0);
          this.booking.additional_miles_charges = toFixed(response.data.data.additional_miles_charges, 0);
          this.booking.miles_charges = toFixed(response.data.data.miles_charges, 0);
          this.booking.wait_time_charges = toFixed(response.data.data.wait_time_charges, 0);
          this.booking.additional_stops_charges = toFixed(response.data.data.additional_stops_charges, 0);
          this.booking.minimum_charge = toFixed(response.data.data.minimum_charge, 0);
          this.setMinimumFee();
          this.$swal.close();
        })
        .catch((error) => {
          let message = getMessageError(error);
          this.$swal({
            title: message,
            icon: "error",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        });
    },
    setTimeZone(pTimeZone) {
      let timeZone = null;

      if (pTimeZone != null) {
        timeZone = pTimeZone;
      } else {
        if (this.selectedCity) {
          let city = this.dataCities.find((x) => x.city == this.selectedCity.value);
          if (city) {
            timeZone = city.time_zone;
          }
        }
      }

      this.selectedTimeZone = this.optionsTimezone.find((x) => x.value == timeZone);

    },
    async saveChanges() {
      if (this.dataRequest.price === null || this.dataRequest.price === "") {
        this.$swal({
          title: 'Price must have a value',
          icon: "error",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });

        return false;
      }
      await this.updateFeesBooking();
      await this.calculatePrice();
      this.validationFormInfoRequest();
    },
    //calculate distance and price
    async doCalculations() {
      if (this.dataRequest.is_automatic_miles == "0") {
        this.calculatePrice();
        return false;
      }
      this.listAddresses = [];
      //add begining
      addAddressToList(
        this.reserva.from.from,
        this.reserva.from.coordinate,
        0,
        0,
        this.listAddresses
      );

      //add additional points
      for (let i = 0; i < this.listAdditionalStopsSelected.length; i++) {
        addAddressToList(
          this.listAdditionalStopsSelected[i].to,
          this.listAdditionalStopsSelected[i].coordinate,
          0,
          0,
          this.listAddresses
        );
      }

      //add ending
      addAddressToList(
        this.reserva.to.to,
        this.reserva.to.coordinate,
        0,
        0,
        this.listAddresses
      );


      if (this.listAddresses.length > 1) {
        //calculate distance
        await this.calculateDistanceIncludingAdditionalPoints(
          this.listAddresses
        );

        //calculate price
        this.calculatePrice();
      }
    },
    //calculate the distance between the start and each point then sort each point by distance between start and the point
    async calculateDistancesFromStartToPoints() {
      let self = this;
      self.listAddressesOrdered = [];

      //set the origin point
      let from = {
        address: this.listAddresses[0].address,
        coordinates: this.listAddresses[0].coordinates,
        distanceFromStart: 0,
        distanceBetwentPoints: 0,
      };

      //set the additional point and the end destination point
      for (let i = 0; i < this.listAddresses.length; i++) {
        //get the info of the current point and save into "to" variable
        let to = {
          address: this.listAddresses[i].address,
          coordinates: this.listAddresses[i].coordinates,
          distanceFromStart: null,
          distanceBetwentPoints: null,
        };

        //if is the begining or the ending dont do calculations
        if (i === 0 || i === this.listAddresses.length - 1) {
          self.listAddressesOrdered.push(to);
          continue;
        }

        //proccess the point to call google api map
        let searchComa = to.coordinates.indexOf(",");
        let latud = to.coordinates.substring(0, searchComa);
        let longi = to.coordinates.substring(
          searchComa + 1,
          to.coordinates.length
        );

        //longitúd y latitúd from
        let searchComaFrom = from.coordinates.indexOf(",");
        let latudFrom = from.coordinates.substring(0, searchComaFrom);
        let longiFrom = from.coordinates.substring(
          searchComaFrom + 1,
          from.coordinates.length
        );

        let origin1 = new google.maps.LatLng(latud, longi);
        let origin2 = to.address;
        let destinationA = from.address;
        let destinationB = new google.maps.LatLng(latudFrom, longiFrom);

        //call the google maps api
        let service = new google.maps.DistanceMatrixService();
        await service.getDistanceMatrix(
          {
            origins: [origin1, origin2],
            destinations: [destinationA, destinationB],
            travelMode: google.maps.TravelMode.DRIVING,
            unitSystem: google.maps.UnitSystem.METRIC,
            avoidHighways: false,
            avoidTolls: false,
          },
          function (response, status) {
            if (response) {
              if (response.rows[0].elements[0].distance) {
                to.distanceFromStart =
                  response.rows[0].elements[0].distance.value / 1609.34;
                //add item to listAddress
                self.listAddressesOrdered.push(to);

                //order the listAddresses according to distanceFromStart
                self.listAddressesOrdered.sort(function (a, b) {
                  return (
                    parseFloat(a.distanceFromStart) -
                    parseFloat(b.distanceFromStart)
                  );
                });
              }
            }
          }
        );
      }
    },
    //calculate the distance including the additional points
    async calculateDistanceIncludingAdditionalPoints() {
      let self = this;
      self.dataRequest.total_miles = 0;

      await self.calculateDistancesFromStartToPoints();

      //set the additional point and the end destination point
      for (let i = 1; i < self.listAddressesOrdered.length; i++) {
        let from = self.listAddressesOrdered[i - 1];
        let to = self.listAddressesOrdered[i];

        //proccess the point to call google api map
        let searchComa = to.coordinates.indexOf(",");
        let latud = to.coordinates.substring(0, searchComa);
        let longi = to.coordinates.substring(
          searchComa + 1,
          to.coordinates.length
        );

        //longitúd y latitúd from
        let searchComaFrom = from.coordinates.indexOf(",");
        let latudFrom = from.coordinates.substring(0, searchComaFrom);
        let longiFrom = from.coordinates.substring(
          searchComaFrom + 1,
          from.coordinates.length
        );

        let origin1 = new google.maps.LatLng(latud, longi);
        let origin2 = to.address;
        let destinationA = from.address;
        let destinationB = new google.maps.LatLng(latudFrom, longiFrom);

        //call the google maps api
        let service = new google.maps.DistanceMatrixService();
        await service.getDistanceMatrix(
          {
            origins: [origin1, origin2],
            destinations: [destinationA, destinationB],
            travelMode: google.maps.TravelMode.DRIVING,
            unitSystem: google.maps.UnitSystem.METRIC,
            avoidHighways: false,
            avoidTolls: false,
          },
          function (response, status) {
            if (response) {
              if (response.rows[0].elements[0].distance) {
                let distance = response.rows[0].elements[0].distance.value / 1609.34;

                if (self.selectedTripType) {
                  if (self.selectedTripType.value === self.ROUNTRIP) {
                    distance = distance * 2;
                  }
                }

                self.dataRequest.total_miles += distance;

              }
            }
          }
        );
      }
    },
    async calculatePrice(showMessage = true) {


      if (this.dataRequest.is_automatic_price != 1) {
        this.totalExtraCharge = GetTotalExtraCharge(this.dataRequest) + parseFloat(this.dataRequest.price);
        return false;
      }
      let formData = new FormData();
      let servicetype = this.selectedService.value;
      let corporate_account_id = 0;
      //this.disablebUpdateButton = true;
      let miles = this.dataRequest.total_miles;
      let waitingTime = this.dataRequest.total_waiting_time === "" ? 0 : this.dataRequest.total_waiting_time;
      let trip_type = this.selectedTripType === undefined ? this.optionsTripType[0].value : this.selectedTripType.value;
      let additionalStop = this.listAdditionalStopsSelected.length;
      //let url = `admin/panel/calculatePriceSelfPay/${miles}/${trip_type}/${waitingTime}/${additionalStop}/${this.dataRequest.id}`;
      let url = `admin/panel/calculate_price_selfpay_nup`;

      if (this.isSelfpay == false) {
        corporate_account_id = this.dataRequest.self_pay.corporate_account.id;
        //url = `admin/panel/calculate_price_corporate_account/${servicetype}/${miles}/${waitingTime}/${trip_type}/${corporate_account_id}/${additionalStop}/${this.dataRequest.id}`;
        url = `admin/panel/calculate_price_corporate_account_nup`;
      }

      formData.append("servicetype", servicetype);
      formData.append("miles", miles);
      formData.append("waitingTime", waitingTime);
      formData.append("tripType", trip_type);
      formData.append("corporate_account_id", corporate_account_id);
      formData.append("additionalStop", additionalStop);
      formData.append("bookingId", this.dataRequest.id);

      await this.$http
        .post(url, formData)
        .then((res) => {

          this.tripSupported = res.data.data.tripSupported;

          if (this.dataRequest.is_automatic_price === 1 || this.dataRequest.is_automatic_price === "1") {
            this.dataRequest.price = res.data.data.totalTrip;

            if (this.isSelfpay == false) {
              this.dataRequest.base_charge = res.data.data.totalBaseEachWay;
              this.dataRequest.additional_miles_charges = null;
            } else {
              this.dataRequest.base_charge = null;
              this.dataRequest.additional_miles_charges = res.data.data.totalAdditionalMiles;
            }

            this.dataRequest.additional_stops_charges = res.data.data.totalAdditionalStop;
            this.dataRequest.wait_time_charges = res.data.data.totalWaitingTime;
            this.dataRequest.miles_charges = res.data.data.totalMiles;

          }

          if (this.dataRequest.is_automatic_additional_stops === 1) {
            this.dataRequest.total_additional_stops = res.data.data.totalAdditionalStop;
          }
          if (this.dataRequest.is_automatic_waiting_time === 1) {
            this.dataRequest.total_waiting_time = res.data.data.totalWaitingTime;
          }

          this.feeAdditionalStop = res.data.data.additional_stop_fee;
          this.feeWaitingTime = res.data.data.wait_time_fee;
          this.dataRequest.each_way_fee = res.data.data.each_way_fee;
          this.dataRequest.mile_fee = res.data.data.mile_fee;
          this.dataRequest.wait_time_fee = res.data.data.wait_time_fee;
          this.dataRequest.additional_stop_fee = res.data.data.additional_stop_fee;
          this.dataRequest.cancellation_fee = res.data.data.cancellation_fee;
          this.dataRequest.minimum_fee = res.data.data.minimum_fee;
          this.dataRequest.minimum_miles_fee = res.data.data.minimum_miles_fee;
          this.dataRequest.additional_miles_fee = res.data.data.additional_miles_fee;

          this.updatePricesAdditionalStops(showMessage);

          this.disablebUpdateButton = false;

          this.totalExtraCharge = GetTotalExtraCharge(this.dataRequest) + parseFloat(this.dataRequest.price);
        })
        .catch((res) => {
          this.disablebUpdateButton = false;
          let message = getMessageError(res);
          this.$swal({
            title: message,
            icon: "error",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        });
    },
    getAdditionalServiceByBookingId(processCalculateDistance) {
      let bookingId = this.dataRequest.id;

      this.$swal({
        title: "Please, wait...",
        didOpen: () => {
          this.$swal.showLoading();
        },
      });

      this.$http
        .get(`admin/panel/booking/${bookingId}/services/view`)
        .then((response) => {
          this.listAdditionalStopsSelected = this.formatListAdditionalStops(
            response.data.data
          );
          if (processCalculateDistance) {
            this.doCalculations();
          }
          this.$swal.close();
        })
        .catch((error) => {
          let message = getMessageError(error);
          this.$swal({
            title: message,
            icon: "error",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        });
    },
    deleteAdditionalStop(item) {
      let bookingId = this.dataRequest.id;
      let serviceId = item.id;

      this.$swal({
        title: "Do you want delete this record?",
        text: "You won't be able to revert this!",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        buttonsStyling: true,
      }).then((result) => {
        if (result.value === true) {
          // <-- if confirmed
          this.$swal({
            title: "Please, wait...",
            didOpen: () => {
              this.$swal.showLoading();
            },
          });

          this.$http
            .post(
              `admin/panel/booking/${bookingId}/service/${serviceId}/delete`
            )
            .then((res) => {
              let index = this.listAdditionalStopsSelected.indexOf(item);
              if (index > -1) {
                this.listAdditionalStopsSelected.splice(index, 1);
                this.doCalculations();
                this.formSubmitted();
              }
            })
            .catch((error) => {
              let message = getMessageError(error);
              this.$swal({
                title: message,
                icon: "error",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
            });
        }
      });
    },
    updateAdditionalStop() {
      if (this.currentAddtionalStop.note == null || this.currentAddtionalStop.note == "") {
        return false;
      }
      if (this.currentAddtionalStop != null) {

        this.$swal({
          title: "Please, wait...",
          didOpen: () => {
            this.$swal.showLoading();
          },
        });

        this.$http
          .post(
            `admin/panel/booking/services/edit_service`,
            this.currentAddtionalStop
          )
          .then((response) => {
            this.resetCurrentAddtionalStop();

            this.$swal({
              title: response.data.message,
              icon: "success",
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: false,
            });
            this.searchAdditionalStop = null;
            this.getAdditionalServiceByBookingId(true);
            this.editAdditionalStop = false;
          })
          .catch((error) => {
            let message = getMessageError(error);
            this.$swal({
              title: message,
              icon: "error",
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: false,
            });
          });
      }
    },
    async addAdditionalStop() {
      if (this.currentAddtionalStop.service == null) {
        return false;
      }
      if (this.currentAddtionalStop != null) {
        let bookingId = this.dataRequest.id;

        this.$swal({
          title: "Please, wait...",
          didOpen: () => {
            this.$swal.showLoading();
          },
        });

        this.$http
          .post(
            `admin/panel/booking/${bookingId}/services/add_one`,
            this.currentAddtionalStop
          )
          .then((response) => {

            this.loadResultAddAdditionalStop();
            this.$swal({
              title: response.data.message,
              icon: "success",
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: false,
            });


          })
          .catch((error) => {
            let message = getMessageError(error);
            this.$swal({
              title: message,
              icon: "error",
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: false,
            });
          });
      }
    },
    async loadResultAddAdditionalStop() {
      this.resetCurrentAddtionalStop();

      this.searchAdditionalStop = null;
      this.listAdditionalStopsSelected.push(this.currentAddtionalStop);
      this.additionalStop = null;
      await this.calculatePrice();
      this.formSubmitted();
      this.getAdditionalServiceByBookingId(true);
    },
    resetCurrentAddtionalStop() {
      this.currentAddtionalStop.id = null;
      this.currentAddtionalStop.service = null;
      this.currentAddtionalStop.to = null;
      this.currentAddtionalStop.to_coordinates = null;
      this.currentAddtionalStop.price = null;
      this.currentAddtionalStop.note = null;
      this.currentAddtionalStop.booking_id = null;
      this.editAdditionalStop = false;
    },
    updatePricesAdditionalStops(showMessage = true) {
      let bookingId = this.dataRequest.id;
      let totalWaitingTime = this.dataRequest.total_waiting_time;
      let totalAdditionalStop = this.dataRequest.total_additional_stops;
      let waitingTime = this.dataRequest.total_waiting_time === "" ? 0 : this.dataRequest.total_waiting_time;

      if (this.listAdditionalStopsSelected.length === 0) {
        return false;
      }
      if (showMessage == true) {
        this.$swal({
          title: "Please, wait updating price additional services...",
          didOpen: () => {
            this.$swal.showLoading();
          },
        });
      }


      this.$http
        .post(
          `admin/panel/booking/${bookingId}/update_prices_additonal_services/${totalWaitingTime}/${totalAdditionalStop}/${waitingTime}`
        )
        .then((res) => {
          this.$swal.close();
        })
        .catch((error) => {
          let message = getMessageError(error);
          this.$swal({
            title: message,
            icon: "error",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        });
    },
    formatListAdditionalStops(data) {
      let res = [];
      let listData = data.filter((x) =>
        x.service.toLowerCase().includes("stop")
      );
      for (let item of listData) {
        let currentData = JSON.parse(item.to);
        res.push({
          id: item.id,
          service: item.service,
          to: currentData.to,
          note: item.note,
          coordinate: currentData.coordinate,
          booking_id: item.booking_id
        });
      }
      return res;
    },
    getWaitingTime(listData) {
      let result = 0;
      let data = listData.filter((x) =>
        x.service.toLowerCase().includes("wait")
      );
      if (data.length > 0) {
        result = data[0].time;
      }
      return result;
    },
    getStates(itemSeleted) {
      this.listStates = [];
      this.selectedState = null;
      this.$http
        .get(`states_cities/get_states`)
        .then((response) => {
          this.loadMenuStates(response.data.data, itemSeleted);
        })
        .catch((error) => {
          this.$swal({
            title: getMessageError(error),
            icon: "error",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        });
    },
    getCitiesByState(state, city) {
      this.listCities = [];
      this.selectedCity = null;
      this.$http
        .get(`states_cities/get_cities_bystate/${state}`)
        .then((response) => {
          this.dataCities = response.data.data;
          this.loadMenuCities(response.data.data, city);
        })
        .catch((error) => {
          this.$swal({
            title: getMessageError(error),
            icon: "error",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        });
    },
    //Set controls page methods and data
    loadData(data, setInitialValues) {
      this.dataRequest = data;
      this.feeWaitingTime = data.wait_time_fee;
      this.feeAdditionalStop = data.additional_stop_fee;
      this.listAdditionalStopsSelected = this.formatListAdditionalStops(data.additional_service);
      this.dataRequest.waitingTime = data.total_waiting_time;
      this.isSelfpay = data.self_pay.ca_id > 0 ? false : true;
      this.dataRequest.total_miles = this.dataRequest.total_miles ? toFixed(this.dataRequest.total_miles, 0) : 0;

      //pickup
      this.reserva.from = JSON.parse(this.dataRequest.from);
      this.reserva.from_coordinates = this.reserva.from.coordinate;
      if (this.reserva.from) {
        try {
          let gpsInfo = this.reserva.from.coordinate.split(",");
          this.fromLatitude = gpsInfo[0];
          this.fromLongitude = gpsInfo[1];
        } catch (error) {

        }
      }
      //destination
      this.reserva.to = JSON.parse(this.dataRequest.to);
      this.reserva.to_coordinates = this.reserva.to.coordinate;
      if (this.reserva.to) {
        try {
          let gpsInfo = this.reserva.to.coordinate.split(",");
          this.toLatitude = gpsInfo[0];
          this.toLongituded = gpsInfo[1];
        } catch (error) {

        }

      }
      this.listAditional = this.listReservas.additional_service;
      this.appoinmentDatetimeSelected = this.dataRequest.appoinment_datetime.split(" ")[1];
      this.ap_date = this.data_time[0];
      this.ap_time = this.data_time[1];
      this.oldStatusBooking = structuredClone(this.dataRequest.status);

      this.dataRequest.is_automatic_pickup_address = this.dataRequest.is_automatic_pickup_address == null ? 1 : this.dataRequest.is_automatic_pickup_address;
      this.dataRequest.is_automatic_destination_address = this.dataRequest.is_automatic_destination_address == null ? 1 : this.dataRequest.is_automatic_destination_address;

      this.setValuesControls(setInitialValues);
      if (setInitialValues) {
        this.getStates(this.dataRequest.state);
        this.getCitiesByState(this.dataRequest.state, this.dataRequest.city);

        // this.doCalculations();           
      }

      // if (this.isSelfpay == true) {
      //   this.optionsServiceOptions = this.optionsServiceOptions.filter((item) => item.value !== 'bls');
      // }
      this.textDefaultMessage = `Confirmation #: ${this.dataRequest.id}\n${this.getSelfPayName(this.dataRequest)}\nPickup:  ${this.convertDate(this.dataRequest.appoinment_datetime)} ${this.convertTime(this.dataRequest.appoinment_datetime)}\nFrom: ${this.getFromLocationBooking(this.dataRequest)}\nDest: ${this.getToLocationBooking(this.dataRequest)}`
      this.totalExtraCharge = GetTotalExtraCharge(this.dataRequest) + parseFloat(this.dataRequest.price);

    },
    async loadMenus() {
      this.optionsTripType = getMenuOptionsTripType();
      this.optionsSurgery = getMenuOptionsSurgeryOptions();
      this.optionsServiceOptions = getMenuOptionsServiceType();
      this.optionsNotes = getMenuNotes();

      this.optionsIsPickupOntime = getMenuPickupOnTime();
      this.optionsTimezone = getMenuTimeZones();
      await this.getStatusCodes();
      await this.getUserList();

      this.selectedTripType = this.optionsTripType[0];
      this.selectedSurgery = this.optionsSurgery[0];
      this.selectedService = this.optionsServiceOptions[0];
      this.selectedIsPickupOntime = this.optionsIsPickupOntime[0];

    },
    setValuesControls(isInitialValue) {

      if (isInitialValue) {
        this.selectedService = this.optionsServiceOptions.find(
          (item) => item.value === this.dataRequest.service_type
        );

        this.selectedTimeZone = this.optionsTimezone.find(
          (item) => item.value === this.dataRequest.time_zone
        );

        if (this.selectedService === undefined) {
          this.selectedService = this.optionsServiceOptions[0];
        }

        this.selectedSurgery = this.optionsSurgery.find((item) => item.value === this.dataRequest.surgery_type);
        if (this.selectedSurgery === undefined) {
          this.selectedSurgery = this.optionsSurgery[0];
        }

        this.selectedTripType = this.optionsTripType.find(
          (item) => item.value === this.dataRequest.trip_type
        );
        if (this.selectedTripType === undefined) {
          this.selectedTripType = this.optionsTripType[0];
        }

        this.selectedIsPickupOntime = this.optionsIsPickupOntime.find(
          (item) => item.value === this.dataRequest.is_pickup_ontime
        );
        if (this.selectedIsPickupOntime === undefined) {
          this.selectedIsPickupOntime = this.optionsIsPickupOntime[0];
        };
      }

      this.selectedStatusCode = this.optionsStatusCodes.find(
        (item) => item.value === this.dataRequest.status
      );

    },
    toFixed(data, numFixed) {
      return toFixed(data, numFixed);
    },
    isNumberVar: function (event) {
      let regex = new RegExp("^[-Z0-9 ]+$");
      let key = String.fromCharCode(
        !event.charCode ? event.which : event.charCode
      );
      if (!regex.test(key)) {
        event.preventDefault();
        return false;
      }
    },
    loadMenuStates(listData, itemSeleted) {
      this.listStates = [];
      listData.forEach((element) => {
        let data = {
          value: `${element.state_full}`,
          title: `${element.state_full}`,
        };
        this.listStates.push(data);
      });
      this.selectedState = this.listStates.find((x) => x.value == itemSeleted);
    },
    loadMenuCities(listData, itemSeleted) {
      this.listCities = [];
      listData.forEach((element) => {
        let data = {
          value: `${element.city}`,
          title: `${element.city}`,
        };
        this.listCities.push(data);
      });
      this.selectedCity = this.listCities.find((x) => x.value == itemSeleted);
      if (this.selectedCity == null) {
        this.selectedCity = this.listCities[0];
      }
    },
    getCityFromApiRespGoogle(data) {
      let item = "0";
      try {
        item = data.address_components.find((x) => x.types[0] == "locality");
        if (item == null) {
          item = data.address_components.find((x) => x.types[0] == "administrative_area_level_3");
        }
        if (item == null) {
          item = data.address_components.find((x) => x.types[0] == "administrative_area_level_1");
        }
        item = item.long_name;
        item = item.toUpperCase();
      } catch (error) { }
      return item;
    },
    getStateFromApiRespGoogle(data) {
      let item = "0";
      try {
        item = data.address_components.find(
          (x) => x.types[0] == "administrative_area_level_1"
        );
        item = item.long_name;
        item = item.toUpperCase();
      } catch (error) { }
      return item;
    },
    getCountyFromApiRespGoogle(data) {
      let item = "0";
      try {
        item = data.address_components.find(
          (x) => x.types[0] == "administrative_area_level_2"
        );
        item = item.long_name;
        item = item.toUpperCase();
        item = item.replace("COUNTY", "");
        item = item.replace("CONDADO DE", "");
        item = item.trim();
      } catch (error) { }
      return item;
    },
    getCentralTime(booking) {
      let res = "";
      if (booking.time_zone) {
        res = getCentralTime(booking, "YYYY-MM-DD HH:mm");
      }
      return res;
    },
    getCompanyName(data) {
      let res = "";
      if (data.self_pay.corporate_account.company_legal_name) {
        res = `${data.self_pay.corporate_account.company_legal_name}`;
      }
      return res;
    },
    getPhoneSelfpay(data) {
      let res = "";
      if (data.self_pay) {
        res = `${data.self_pay.phone_number}`;
      }
      return res;
    },
    getEmailSelfpay(data) {
      let res = "";
      if (data.self_pay) {
        res = `${data.self_pay.email}`;
      }
      return res;
    },
    getComment(comment) {
      // return comment.split("array");
      return comment;
    },
    async getStatusCodes() {
      await this.$http
        .get(`get_list_status`)
        .then((response) => {
          let data = response.data;
          this.optionsStatusCodes = [];
          data.forEach(element => this.optionsStatusCodes.push({ 'title': element.status, 'value': element.code }));
        })
        .catch((error) => {
          this.$swal({
            title: getMessageError(error),
            icon: "error",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        });
    },
    async getUserList() {
      await this.$http
        .get(`admin/panel/users/list?all=all`)
        .then((response) => {
          let data = response.data.data;
          this.optionsUser = [];
          this.optionsUserProviderRelations = [];
          data.forEach(element => this.optionsUser.push({ 'title': `${element.name}`, 'value': `${element.name} || ${element.email}` }));
          data.forEach(element => {
            if (element.role) {
              if (element.role.role == 'Provider Relations') {
                this.optionsUserProviderRelations.push({ 'title': `${element.name} || ${element.email}`, 'value': `${element.name} || ${element.email}` })
              }
            }
          }

          );

        })
        .catch((error) => {
          this.$swal({
            title: getMessageError(error),
            icon: "error",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        });
    },
    async saveNote(typeNote, typelog) {
      if (this.messageText === "") {
        this.isMessageText = false;
      } else {
        this.$bvModal.hide("bv-modal-example");
        this.$bvModal.hide("bv-modal-sms");

        this.dataRequest.alternative_number_driver = this.dataRequest.alternative_number_driver == null
          ? ""
          : this.dataRequest.alternative_number_driver;
        this.dataRequest.alternative_number_passenger = this.dataRequest.alternative_number_passenger == null
          ? ""
          : this.dataRequest.alternative_number_passenger;

        this.$swal({
          title: "Please, wait...",
          didOpen: () => {
            this.$swal.showLoading();
          },
        });
        let formData = new FormData();
        formData.append("text", this.messageText);
        formData.append("status", this.dataRequest.status);
        formData.append("messageSendTo", this.messageSendTo);
        formData.append("type_log", typelog);
        formData.append("type_note", typeNote);
        formData.append("alternative_number_driver", this.dataRequest.alternative_number_driver);
        formData.append("alternative_number_passenger", this.dataRequest.alternative_number_passenger);
        formData.append("datetime_log", moment().format("YYYY-MM-DD hh:mm A"));

        this.$http
          .post(
            `/admin/panel/booking/${this.dataRequest.id}/saveBookingNote`,
            formData
          )
          .then((res) => {
            let message = "";
            if (typelog === 'sms') {
              message = 'Sms sent';
            } else {
              message = res.data.message;
            }

            this.messageText = "";
            this.$swal({
              title: message,
              icon: "success",
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: false,
            }).then((result) => {
              this.messageText = "";
              this.getInfoBooking();
            });
          })
          .catch((error) => {
            this.$swal({
              title: getMessageError(error),
              icon: "error",
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: false,
            });
          });
      }
    },
    updateNote() {
      this.$bvModal.hide("modal-crud-notes");
      this.$swal({
        title: "Please, wait...",
        didOpen: () => {
          this.$swal.showLoading();
        },
      });

      let formData = new FormData();
      formData.append("id", this.note.id);
      formData.append("comments", this.note.comments);
      formData.append("type_note", this.note.type_note);

      this.$http
        .post(
          `/admin/panel/booking/edit_booking_note`,
          formData
        ).then((res) => {
          this.$swal({
            title: res.data.message,
            icon: "success",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          }).then((result) => {

          });
        }).catch((error) => {
          this.$swal({
            title: getMessageError(error),
            icon: "error",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        });
    },
    deleteNote(id) {
      this.$swal({
        title: "Do you want delete this record?",
        text: "You won't be able to revert this!",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        buttonsStyling: true,
      }).then((result) => {
        if (result.value === true) {
          this.$swal({
            title: "Please, wait...",
            didOpen: () => {
              this.$swal.showLoading();
            },
          });
          this.$http
            .post(`/admin/panel/delete_booking_note/${id}`)
            .then((res) => {
              this.dataRequest.Bookinglogs = this.dataRequest.Bookinglogs.filter((item) => item.id !== id);
              this.$swal({
                title: res.data.message,
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
            })
            .catch((error) => {
              this.$swal({
                title: getMessageError(error),
                icon: "error",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
            });
        }
      });
    },
    changeColorMessage() {
      this.isMessageText = true;
    },
    getGarageTime(item) {
      return moment(item).format('MM-DD-YYYY hh:mm A')
    },
    async loadInformation() {
      await this.loadMenus();
      await this.getInfoBooking();

    },
    getFromLocationBooking(booking) {
      let res = "";

      try {
        res = JSON.parse(booking.from).from;
      } catch (error) {
      }
      return res;
    },
    getToLocationBooking(booking) {
      let res = "";

      try {
        res = JSON.parse(booking.to).to;
      } catch (error) {
      }
      return res;
    },
    getSelfPayName(booking) {
      let res = "";

      try {
        res = `${booking.self_pay.name} ${booking.self_pay.lastname}`
      } catch (error) {
      }
      return res;
    },
    setDefaulText() {
      if (this.isDefaultMessage == true) {
        this.messageText = this.textDefaultMessage;
      } else {
        this.messageText = "";
      }

    },
    convertDate(data) {
      return convertToMMDDYYYY(data);
    },
    convertTime(data) {
      return convertToHHMM(data);
    },
    assingEmployee() {
      this.dataRequest.assigned_employee = Object.assign(this.selectedAssingedEmployee.value);

      this.selectedAssingedEmployee = null;
    },
    assingProviderRelations() {
      this.dataRequest.provider_relations = Object.assign(this.selectedProviderRelations.value);

      this.selectedProviderRelations = null;
    },
    openCreateRate() {
      let servicetype = "";
      let ca_id = null;
      if (this.isSelfpay === false) {
        if (this.selectedService) {
          servicetype = this.selectedService.value;
        }
        if (this.dataRequest.self_pay.corporate_account) {
          ca_id = this.dataRequest.self_pay.corporate_account.id;
        }
        const routeData = this.$router.resolve({ path: `/form-prices-corporate-account/0/${servicetype}/${ca_id}`, query: { redirect: 1 } });
        window.open(routeData.href, '_blank');
        this.$refs.modalcreaterates.hide();
      }
    },
    async changeServiceType() {
      await this.calculatePrice();

      if (this.tripSupported === "no") {
        this.$refs.modalcreaterates.show();
        return false;
      }
    },
    addDescriptionNote() {
      this.messageText += this.selectedDescriptionNote.value + "\n";
      this.selectedDescriptionNote = null;
    },
    openModalEditNote(item) {
      this.note = item;
      this.$bvModal.show("modal-crud-notes");
    },
    hasPermissionEditBookingNote() {
      return this.$hasPermission('edit-booking-note');
    },
    hasPermissionDeleteBookingNote() {
      return this.$hasPermission('delete-booking-note');
    },
    hasPermissionInternalfinancialreports() {
      return this.$hasPermission('internal-financial-reports');
    },
    changeStatusBooking() {
      this.dataRequest.status = this.selectedStatusCode.value
    },
    setValuesWalkIn() {
      if (this.appoinmentDatetimeSelected == null || this.appoinmentDatetimeSelected == "" || this.appoinmentDatetimeSelected == undefined) {
        this.appoinmentDatetimeSelected = "00:00";
      }
    },
    editAddtionalStop(item) {
      this.currentAddtionalStop = structuredClone(item);
      this.editAdditionalStop = true;
    },
    async saveChargesBooking() {
      this.$swal({
        title: "Please, wait...",
        didOpen: () => {
          this.$swal.showLoading();
        },
      });

      let url = `admin/panel/update-charges-booking`;
      await this.$http
        .post(url, this.dataRequest)
        .then((res) => {
          this.$swal.close();
        })
        .catch((res) => {
          this.$swal.close();
          let message = getMessageError(res);
          this.$swal({
            title: message,
            icon: "error",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        });
    },
    setMinimumFee() {
      if ((this.dataRequest.is_automatic_price == "1" || this.dataRequest.is_automatic_price == 1) == false) {
        this.dataRequest.minimum_fee = null;
      }
    },
    debounce(event)
    {
      const handleDebouncedChange = debounce((event) => {
        console.log('Debounced value:', event.target.value);
    }, debounceDelay);
    }

  },
  mounted() {
    this.loadInformation();

    this.$root.$on('extracharge_was_updated', (data) => {
      this.dataRequest.extra_charge = data;
      if (this.dataRequest.price == "" || this.dataRequest.price == null) {
        this.totalExtraCharge = 0;
      } else {
        this.totalExtraCharge = this.dataRequest.price;
      }

      this.totalExtraCharge = GetTotalExtraCharge(this.dataRequest) + this.totalExtraCharge;
    });
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

.circle-number-additional {
  background-color: #332b7b;
  padding: 5px 12px;
  border-radius: 16px;
  color: white;
}

.btn-primary {
  background: #7367f0 !important;
}

.tbTotal,
table.tbTotal th,
table.tbTotal td {
  // border: 1px solid #c6c6c6;
  margin-left: auto;
  margin-bottom: 20px;
  background: #efefef;
  color: #0e0d0d;
  padding-left: 5px;
  padding-bottom: 3px;
}

.modal-lg {
  max-width: 65%;
}
</style>