<template>
  <div>
    <div>
      <template v-if="infocard == null && clientIsContract(booking) === false">
        <h4 class="pt-1 my-3 text-center" style="color: #ff0000">
          No payment method added
        </h4>
        <div class="pt-1 my-2 text-center">
          <b-button variant="success" v-b-modal.modal-add-payment-method>
            Add payment method
          </b-button>
        </div>
      </template>


      <template v-if="infocard != null && clientIsContract(booking) === false">
        <h3>Current payment method</h3>
        <b-row>

          <b-col cols="12" md="6">
            <b-form-group label="Name on Credit Card">
              <b-form-input v-model="infocard.name" disabled />
            </b-form-group>
          </b-col>
          <b-col cols="12" md="6">
            <b-form-group label="Card Number">
              <b-form-input v-model="'********' + infocard.last4" disabled />
            </b-form-group>
          </b-col>

          <b-col cols="12" md="6">
            <b-form-group label="Expiration date">
              <b-form-input v-model="infocard.exp_month + ' / ' + infocard.exp_year" disabled />
            </b-form-group>
          </b-col>
          <b-col cols="12" md="6">
            <b-form-group label="Card Use">
              <b-form-select id="Status" v-model="booking.self_pay.card_use" :options="optionsCardUsed" disabled>
                <option value="">Chose Option</option>
              </b-form-select>
            </b-form-group>
          </b-col>
        </b-row>
      </template>

      <template v-if="clientIsContract(booking) === true">
        <h4 class="pt-1 my-3 text-center" style="color:#6342cf">
          This is a contract account
        </h4>
        <h4 class="pt-1 my-3 text-center" style="color: #6342cf;">
          Invoice later
        </h4>
      </template>


      <h4 v-if="chargePayment === null && clientIsContract(booking) === false" class="pt-1 my-3 text-center"
        style="color: #ff0000">
        No charge was found
      </h4>
    </div>
    <div v-if="clientIsContract(booking) === false && chargePayment !== null">
      <div class="d-flex align-items-center mb-2">
        <h3>Payment details</h3>
      </div>

      <template>
        <b-form>
          <b-row>
            <b-col cols="12" md="4">
              <b-form-group label="Name">
                <b-form-input disabled v-model="chargePayment.billing_details.name" />
              </b-form-group>
            </b-col>
            <b-col cols="12" md="8">
              <b-form-group label="Description">
                <b-form-textarea disabled v-model="chargePayment.description" />
              </b-form-group>
            </b-col>
            <b-col cols="12" md="4">
              <b-form-group label="Amount dolars">
                <b-form-input v-model="chargePayment.amount" disabled />
              </b-form-group>
            </b-col>
            <b-col cols="12" md="4">
              <b-form-group label="Card">
                <b-form-input v-model="chargePayment.payment_method_details.card.brand" disabled />
              </b-form-group>
            </b-col>
            <b-col cols="12" md="3">
              <b-form-group label="Funding">
                <b-form-input disabled v-model="chargePayment.payment_method_details.card.funding" />
              </b-form-group>
            </b-col>
            <b-col cols="12" md="2">
              <b-form-group label="Expiration month">
                <b-form-input disabled v-model="chargePayment.payment_method_details.card.exp_month" />
              </b-form-group>
            </b-col>
            <b-col cols="12" md="2">
              <b-form-group label="Expiration year">
                <b-form-input disabled v-model="chargePayment.payment_method_details.card.exp_year" />
              </b-form-group>
            </b-col>
            <b-col cols="12" md="4">
              <b-form-group label="Number credit card">
                <b-form-input disabled v-model="'*******' + chargePayment.payment_method_details.card.last4
                  " />
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </template>
      <hr />
    </div>


    <b-modal ref="modal-add-payment-method" id="modal-add-payment-method" cancel-only centered size="lg"
      title="Add payment method">

      <!-- Edit payment -->
      <!-- <template v-if="aggPayment === true"> -->
      <template v-if="clientIsContract(booking) === false && infocard == null">
        <h4 class="mb-0 ml-50">

        </h4>
        <b-form class="mt-1">

          <b-col>
            <b-row>
              <b-col cols="12" md="6" lg="6">
                <b-form-group label="Name on Credit Card">
                  <b-form-input v-model="paymentMethods.name_on_cc" @keypress="isText" maxlength="30" />
                </b-form-group>
              </b-col>
              <b-col cols="12" md="6" lg="6">
                <b-form-group label="Card Number">
                  <b-form-input v-model="paymentMethods.cc_number" @keypress="isNumber($event)" maxlength="16" />
                </b-form-group>
              </b-col>

              <b-col cols="12" md="6" lg="4">
                <b-form-group label="CVC">
                  <b-form-input v-model="paymentMethods.code_of_cc" @keypress="isNumber($event)" maxlength="3"
                    placeholder="***" />
                </b-form-group>
              </b-col>

              <b-col cols="12" md="6" lg="4">
            <b-form-group label="Card Use">
              <b-form-select
                id="Status"
                v-model="paymentMethods.card_use"
                :options="optionsCardUsed"
              >
                <option value="">Chose Option</option>
              </b-form-select>
            </b-form-group>
          </b-col>

              <b-col cols="12" md="6" lg="4">
                <b-form-group label="Expiration">
                  <b-form-input v-model="paymentMethods.exp" v-mask="'##/####'" hint="MM/YYYY" placeholder="MM/YYYY" />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col class="mt-2">
                <b-button @click="SavePaymentMethod" variant="primary" class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                  >
                  Save Changes
                </b-button>
                <b-button @click="$bvModal.hide('modal-add-payment-method')" variant="outline-secondary"
                  >
                  Cancel
                </b-button>
              </b-col>
            </b-row>

          </b-col>
        </b-form>
      </template>

      <template #modal-footer>
        <div class="w-100">
        </div>
      </template>
    </b-modal>

  </div>
</template>

<script>
import {
  BButton,
  BMedia,
  BAvatar,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BForm,
  BTable,
  BCard,
  BCardHeader,
  BCardTitle,
  BFormCheckbox,
  BFormSelect,
  VBTooltip,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { getMessageError } from "@core/utils/utils";
import { getMenuCardMenu } from "@core/utils/menus";

export default {
  name: "DetailsInfoPaymentBooking",
  components: {
    BButton,
    BMedia,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BForm,
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
    BFormSelect,
    vSelect,
  },
  props: {
    booking: {},
  },
  data() {
    return {
      chargePayment: null,
      reservaId: "",
      optionsCardUsed: null,
      selectedCardUse: null,
      infocard: null,
      paymentMethods: {
        name_on_cc: "",
        cc_number: "",
        brand: "",
        code_of_cc: "",
        exp: "",
        exp_month: "00",
        exp_year: "0000",
        last4: "",
        card_use: "",
      },
    };
  },
  methods: {
    getChargePayments() {
      let clientId=0;

      if (this.booking.charge_id !== null) {
        this.getInfoCharge(this.booking.charge_id);
      }
      if(this.clientIsSelfpay(this.booking))
      {
        clientId = this.booking.self_pay.id;
      }
      if(this.clientIsCorporate(this.booking))
      {
        clientId = this.booking.self_pay.corporate_account.id;
      }  
      this.getCard(clientId);
    },
    getInfoCharge(charge_id) {
      this.$swal({
        title: "Please, wait...",
        didOpen: () => {
          this.$swal.showLoading();
        },
      });
      this.$http
        .get(`admin/panel/charges/${charge_id}/show`)
        .then((response) => {
          if (response.data.data) {
            this.chargePayment =response.data.data === undefined ? null : response.data.data;
            this.chargePayment.amount = this.getAmount(this.chargePayment.amount);
            this.chargePayment.amount_captured = this.getAmount(this.chargePayment.amount_captured);
          }
          this.$swal.close();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getCard(id) {
      let url = "";
      let isSelfpay = this.clientIsSelfpay(this.booking);
      let isCorporateAccount = this.clientIsCorporate(this.booking);

      if (id == null)
        return false;

      if (isSelfpay) {
        url = `admin/panel/selfpaid/${id}/paymentMethodbyid`;
      }
      if (isCorporateAccount) {
        url = `ca/${id}/panel/paymentMethod`;
      }

      this.$http
        .get(url)
        .then((response) => {
          if (response.data.data) {
            if (isSelfpay) {
              this.infocard = response.data.data.payment === undefined ? null : response.data.data.payment;
            }
            if (isCorporateAccount) {
              this.infocard = response.data.data
            }

          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    loadOptionsMenuCardUsed() {
      this.optionsCardUsed = getMenuCardMenu();
    },
    setMenusValues() {
      let SelfPay = this.getSelfPay(this.booking);
      this.selectedCardUse = this.optionsCardUsed.find(
        (x) => x.value == SelfPay.card_use
      );
    },
    getSelfPay(data) {
      let res = null;
      if (data.self_pay) {
        res = data.self_pay;
      }
      return res;
    },
    getAmount(amount) {
      let res = 0;
      try {
        res = amount / 100;
      } catch (error) {

      }
      return res;
    },
    isText: function (event) {
      let regex = new RegExp("^[a-zA-Z ]+$");
      let key = String.fromCharCode(
        !event.charCode ? event.which : event.charCode
      );
      if (!regex.test(key)) {
        event.preventDefault();
        return false;
      }
    },
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      let charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    SavePaymentMethod() {
      this.inhabilitar = true;
      let url = "";
      let clientId = 0;

      if (this.clientIsSelfpay(this.booking) === true) {
        clientId = this.booking.self_pay.id;
        url = `admin/panel/selftpay/paymentmethod/add`;
        this.paymentMethods.card_use = this.booking.self_pay.card_use;
      }

      if (this.clientIsCorporate(this.booking) === true) {
        let stripe_customer_id = this.booking.self_pay.corporate_account.corporate_account_personal_info.stripe_customer_id;
        clientId = this.booking.self_pay.corporate_account.id;

        url = `ca/panel/corporate/${stripe_customer_id}/${clientId}/AddStripePaymentMethod`
      }

      //Get month and year expiration
      let expirationTimeData = this.paymentMethods.exp.split("/");
      this.paymentMethods.exp_month = expirationTimeData[0];
      this.paymentMethods.exp_year = expirationTimeData[1];
      this.paymentMethods.clientId=this.booking.self_pay.client_id;

      this.$swal({
        title: "Please, wait...",
        didOpen: () => {
          this.$swal.showLoading();
        },
      });

      this.$http
        .post(url, this.paymentMethods)
        .then((res) => {
          if (res.data.status === 200) {
            this.$swal({
              title: "Record modified successfully",
              icon: "success",
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: false,
            });
          //  bvModal.hide('modal-add-payment-method');
            this.$bvModal.hide('modal-add-payment-method')
            this.getCard(clientId);
          } else {
            this.$swal({
              title: res.data.message,
              icon: "error",
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: false,
            });
          }
        })
        .catch((res) => {
          let message = res.message;
          if (res.response.data) {
            message = res.response.data.data;
          }
          this.$swal({
            title: message,
            icon: "error",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        });
    },
    clientIsSelfpay(item) {
      let res = false;
      if (item.self_pay) {
        res = item.self_pay.corporate_account === null ? true : false;
      }
      return res;
    },
    clientIsCorporate(item) {
      let res = false;
      if (item.self_pay.corporate_account) {
        res = item.self_pay.corporate_account.corporate_account_personal_info.payment_type === 'cc' ? true : false;
      }
      return res;
    },
    clientIsContract(item) {
      let res = false;
      if (item.self_pay.corporate_account) {
        res = item.self_pay.corporate_account.corporate_account_personal_info.payment_type === 'il' ? true : false;
      }
      return res;
    },
  },
  mounted() {
    this.loadOptionsMenuCardUsed();
    this.setMenusValues();
    this.getChargePayments();
  },
  updated() { },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

.circle-number-additional {
  background-color: #332b7b;
  padding: 5px 12px;
  border-radius: 16px;
  color: white;
}
</style>
